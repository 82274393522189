import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

import image1 from '../images/bandeau.png';

const Contact = ({ data }) => {

  return (
    <Layout>
      <SEO title="Adultes" />
      <Header metadata={data.site.siteMetadata} />
      <br/><br/>
      Mon numéro de téléphone: 0496/61.70.22 <br/><br/>
      <a href={`mailto:duchevalasoi@gmail.com?subject=Equicoaching`}>
        Mon email: duchevalasoi@gmail.com
      </a> <br/><br/>
      Mon facebook
      <Link className="text-gray-900 text-base hover:text-black" to="https://www.facebook.com/TheateNancy">
        : cliquez ici
      </Link>
      <br/><br/>
      <img width={450} height={600} src={image1} alt=""/>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
